<template>
  <!-- table -->
  <b-overlay
    :show="loading"
    opacity="0.3"
    variant="transparent"
    spinner-variant="primary"
    spinner-type="border"
    rounded="sm"
    style="min-height: 200px;"
  >
    <vue-good-table
      v-if="domains.length > 0"
      mode="remote"
      :columns="columns"
      :rows="domains"
      :pagination-options="{
        enabled: true,
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: ID -->
        <span
          v-if="props.column.field === '_id'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ props.row._id }}</span>
        </span>

        <!-- Column: Fullname -->
        <span v-else-if="props.column.field === 'name'">
          <b-badge variant="light-info">
            {{ props.row.name }}
          </b-badge>
        </span>

        <!-- Column: URL -->
        <span v-else-if="props.column.field === 'url'">
          {{ props.row.url }}
        </span>

        <!-- Column: Email -->
        <span v-else-if="props.column.field === 'email'">
          <b-badge variant="light-warning">
            {{ props.row.email }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'actions'">
          <span class="d-flex justify-content-around">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="btn-icon rounded-circle"
              @click="viewItem(props.row._id)"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-secondary"
              class="btn-icon rounded-circle"
              @click="editItem(props.row._id)"
            >
              <feather-icon icon="Edit2Icon" />
            </b-button>
            <b-button
              variant="outline-danger"
              class="btn-icon rounded-circle"
              @click="confirmText(props.row)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>

      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
      >
        <div class="d-flex justify-content-between flex-wrap mt-1 mb-0">
          <!-- pagination -->
          <div>
            <b-pagination
              :value="currentPage"
              :total-rows="rows.totalDocs"
              :per-page="rows.limit"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class=""
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-overlay>
</template>

<script>
import {
  BBadge, BPagination, BButton, BOverlay,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'Domains',
  components: {
    VueGoodTable,
    BBadge,
    BPagination,
    BButton,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  props: {
    page: {
      type: String,
      default: '1',
    },
  },
  data() {
    return {
      columns: [
        {
          field: '_id',
          label: 'ID',
          sortable: false,
        },
        {
          field: 'name',
          label: 'Name',
          sortable: false,
        },
        {
          field: 'url',
          label: 'URL',
          sortable: false,
        },
        {
          field: 'email',
          label: 'Email',
          sortable: false,
        },
        {
          field: 'actions',
          label: 'Actions',
          sortable: false,
        },
      ],
      rows: [],
      loading: false,
    }
  },
  computed: {
    domains() {
      return (this.$store.state.app && this.$store.state.app.domains) ? this.$store.state.app.domains : []
    },
    currentPage() {
      return this.page ? parseInt(this.page, 10) : 1
    },
  },
  watch: {
    // eslint-disable-next-line
    '$route.params.page': async function (page) {
      await this.loadData()
    },
  },
  async beforeMount() {
    this.loadDomains()
  },
  methods: {
    async loadDomains() {
      const self = this

      self.loading = true
      await this.$store.dispatch('app/fetchDomains', {
        page: this.currentPage,
      })
        .then(() => {
          self.rows = self.$store.state.app.domains
        })
        .catch(() => {
        })
        .finally(() => {
          self.loading = false
        })
    },
    handleChangePage(page) {
      this.$router.push({ name: 'trips', params: { page: `${page}` } })
    },
    editItem(item) {
      this.$router.push({ name: 'editDomain', params: { domainId: item } })
    },
    viewItem(item) {
      this.$router.push({ name: 'viewDomain', params: { domainId: item } })
    },
    deleteItem(item) {
      this.$store.dispatch('app/deleteDomain', item).catch()
    },
    createNew() {
      this.$router.push({ name: 'createDomain' })
    },
  },
}
</script>

<style>

</style>
